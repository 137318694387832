<script>


/**
 * Cheque Print  component
 */


import {
    required,
} from "vuelidate/lib/validators";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import { getCashApi } from '@/api/cash'
import simplebar from "simplebar-vue";
import { financial } from '@/api/misc'
import moment from 'moment'
export default {

    emits: ['close', 'reprint', 'reverse'],

    props: ['transaction'],
    components: {
        simplebar,
        flatPickr

    },

    validations: {
        cheque_id: { required },
        reverse_date: { required }
    },



    data() {

        return {
            submitted: false,
            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "Y-m",
                altFormat: "Y-m",
                ariaDateFormat: "Y-m",
            },
            bank_account: '',
            total_amount: 0,
            post_date: '',
            desc: '',
            type: '',
            detail_items: [],
            reprint_flag: true,
            enabled_reverse: true,

            nsf_transaction: {},
            eft_return_tran: {},
            reverse_date: '',
            note: ''


        }

    },
    methods: {
        f(v, d = 2) {
            return financial(v, d)
        },

        get_post_date(d) {
            return moment(d).format("YYYY-MM-DD")
        },

        onReprint() {
            this.$emit('reprint')
        },



        onReverse() {
            this.$emit('reverse')
        },

        show_nsf_dialog(t) {
            this.nsf_transaction = t
            this.$bvModal.show('nsf_dialog')
        },

        show_eft_return(t) {
            this.eft_return_tran = t
            this.$bvModal.show('eft_return_dialog')
        },

        do_eft_return() {
            this.$v.$touch();
            this.$v.reverse_date.$touch()
            if (this.$v.reverse_date.$invalid) {
                return
            }
            getCashApi().reverse_cash_transaction(this.eft_return_tran, 'EFT_RETURN', this.reverse_date).then(res => {
                if (res.errCode == 0) {
                    this.eft_return_tran.reverse_flag = '1'
                    this.$alertify.message("Reversed Succeed ");
                    this.$bvModal.hide('eft_return_dialog')
                } else {
                    this.$alertify.error("Reversed Failed " + res.errCode + "  msg:" + res.msg);
                }
            })
        },

        do_nsf() {
            this.$v.$touch();
            this.$v.reverse_date.$touch()
            if (this.$v.reverse_date.$invalid) {
                return
            }

            getCashApi().reverse_cash_transaction(this.nsf_transaction, 'NSF', this.reverse_date).then(res => {
                if (res.errCode == 0) {
                    this.nsf_transaction.reverse_flag = '1'
                    this.$alertify.message("Reversed Succeed ");
                    this.$bvModal.hide('nsf_dialog')
                } else {
                    this.$alertify.error("Reversed Failed " + res.errCode + "  msg:" + res.msg);
                }
            })
        }


    },

    created() {


    },

    mounted() {

        let data = {
            transaction_id: this.transaction.id
        }
        getCashApi().query_transaction_detail(data).then(res => {
            if (res.errCode == 0) {
                this.bank_account = res.data.bank_account
                this.type = res.data.type
                this.desc = res.data.remarks
                this.total_amount = res.data.amount
                this.post_date = res.data.transaction_time
                this.detail_items = res.data.details
                this.enabled_reverse = res.data.is_reversed == 1 ? false : true

            }
        })

    },



};
</script>

<template>

    <div class="card">

        <b-modal id="nsf_dialog" title="NSF Cheque" size="lg" hide-footer>
            <div class="row">
                <div class="col-md-4">
                    <div class="mb-3">
                        <label>Post Date</label>
                        <flat-pickr v-model="reverse_date" placeholder="Select a date" class="form-control"
                            :class="{ 'is-invalid': $v.reverse_date.$error }"></flat-pickr>
                        <div v-if="$v.reverse_date.$error" class="invalid-feedback">
                            <span v-if="!$v.reverse_date.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="mb-3">
                        <label>Note</label>
                        <input type="text" class="form-control" v-model="note" />
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-md-12">
                    <div class="d-flex flex-wrap gap-2 justify-content-end">
                        <b-button variant="secondary" type="button"
                            @click="$bvModal.hide('nsf_dialog')">Close</b-button>
                        <b-button variant="danger" type="button" @click="do_nsf()">Confirm</b-button>
                    </div>
                </div>

            </div>
        </b-modal>

        <b-modal id="eft_return_dialog" title="EFT Return" size="lg" hide-footer>
            <div class="row">
                <div class="col-md-4">
                    <div class="mb-3">
                        <label>Post Date</label>
                        <flat-pickr v-model="reverse_date" placeholder="Select a date" class="form-control"
                            :class="{ 'is-invalid': $v.reverse_date.$error }"></flat-pickr>
                        <div v-if="$v.reverse_date.$error" class="invalid-feedback">
                            <span v-if="!$v.reverse_date.required">This value is required.</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="mb-3">
                        <label>Note</label>
                        <input type="text" class="form-control" v-model="note" />
                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-md-12">
                    <div class="d-flex flex-wrap gap-2 justify-content-end">
                        <b-button variant="secondary" type="button"
                            @click="$bvModal.hide('eft_return_dialog')">Close</b-button>
                        <b-button variant="danger" type="button" @click="do_eft_return()">Confirm</b-button>
                    </div>
                </div>

            </div>
        </b-modal>



        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Bank Account</label>
                            <input class="form-control" :value="bank_account" name="choices-bank-account" readonly />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Amount</label>
                            <input class="form-control" :value="total_amount" name="choices-bank-account" readonly />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Post Date</label>
                            <input class="form-control" :value="get_post_date(post_date)" name="choices-bank-account"
                                readonly />
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-12 mb-3">
                        <label for="choices-bank-account" class="form-label">Description</label>
                        <input type="text" class="form-control" :value="desc" readonly />
                    </div>
                </div>



                <simplebar class="table-container" data-simplebar-auto-hide="false">
                    <div class="table-responsive" style="max-height: 400px;">
                        <div class="table align-middle table-nowrap" style="height:100%">
                            <b-table :items="detail_items" :fields="['Amount', 'Remark', 'Action']" responsive="sm"
                                :per-page="detail_items.length" class="table-check">

                                <template #cell(Amount)="data">
                                    {{ data.item.amount }}
                                </template>
                                <template #cell(Remark)="data">
                                    {{ data.item.remarks }}
                                </template>
                                <template #cell(Action)="data">
                                    <b-dropdown variant="white" right toggle-class="btn-link text-dark shadow-none">
                                        <template v-slot:button-content>
                                            <i class="uil uil-ellipsis-h"></i>
                                        </template>
                                        <b-dropdown-item
                                            :disabled="transaction.type != 'EFT' || data.item.reverse_flag == '1'"
                                            @click="show_eft_return(data.item)">EFT Retrun</b-dropdown-item>
                                        <b-dropdown-item
                                            :disabled="(transaction.type != 'CHEQUE' && transaction.type != 'PAD') || data.item.reverse_flag == '1' || data.item.action != 'DEPOSIT'"
                                            @click="show_nsf_dialog(data.item)">NSF</b-dropdown-item>

                                    </b-dropdown>

                                </template>
                            </b-table>
                        </div>
                    </div>
                </simplebar>

                <div class="row mt-3">
                    <div class="col-md-12">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('close')">Close</b-button>
                            <!-- <b-button variant="primary" type="button" @click="onReprint"
                                v-if="type == 'CHEQUE'">Re-Print</b-button> -->
                            <!-- <b-button variant="primary" type="button" @click="onReverse"
                                v-if="(type == 'CHEQUE' || type == 'EFT') && enabled_reverse == true">Reverse</b-button> -->
                        </div>
                    </div>


                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>